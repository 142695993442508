// src/components/Header/Header.js
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FaBars, FaTimes} from 'react-icons/fa';
import {MENU_ITEMS, PATH, STORE, VARIANT} from '../../constants';
import MenuItem from '../Common/MenuItem';
import {handleActiveItem} from '../../services/services';
import {useLocation} from 'react-router-dom';
import Button from '../Common/Button';
import useClickOutside from '../../hooks/useClickOutside';
import Popup from '../Popup';
import NewLink from "../NewLink/NewLink";
import AppDownload from "./AppDownload";
import {isAndroid, isMobile} from "react-device-detect";

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	// const [isMobile, setIsMobile] = useState(false);
	const {t} = useTranslation();
	const location = useLocation();
	const {pathname, hash} = location || {};
	const activeId = handleActiveItem(pathname);
	const menuRef = useRef(null);
	
	const queryParams = new URLSearchParams(location.search);
	const jump = queryParams.get("jump");
	//get query params on url

	useEffect(() => {
		setIsOpen(false);
		// scroll to top when route changes smoothly
		if (!hash) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [hash, pathname]);
	
	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};
	
	const onDownload = (e) => {
		e.preventDefault();
		setIsOpen(false);
		if(jump) {
			openApp();
		} else if(isMobile) {
			if(isAndroid) {
				window.location.href = STORE.PLAY_STORE
			} else {
				window.location.href = STORE.APPS_STORE
			}
		}
	};
	
	useClickOutside(menuRef, () => setIsOpen(false));
	
	const openApp = () => {
		const appUrl = "goopay://merchantBilling/123";
		const appStoreUrl = "https://apps.apple.com/sg/app/goopay-ewallet/id6444816745";
		
		// Attempt to open the app
		window.location = appUrl;
		
		// Redirect to the App Store after a delay if the app is not installed
		setTimeout(() => {
			window.location = appStoreUrl;
		}, 1500); // 1.5 seconds delay
	}
	
	return (
		<header className="bg-white md:bg-[#ffffffe5] shadow-md h-[90px] sticky top-0 z-50">
			<div className="px-8 md:px-[120px] py-4 flex flex-wrap items-center justify-between bg-white md:bg-transparent">
				<div>
					<NewLink to={PATH.HOME}>
						<img
							src="/img/logo.png"
							alt="Restaurant Logo"
							className="h-12 w-auto"
						/>
					</NewLink>
				</div>
				<button
					onTouchEnd={toggleMenu}
					className="md:hidden text-theme-color-primary focus:outline-none"
					aria-label="Toggle menu"
				>
					{isOpen ? <FaTimes id={'FaTimes'} size={24}/> : <FaBars id={'FaBars'} size={24}/>}
				</button>
				<nav
					ref={menuRef}
					className={`${
						isOpen ? 'block' : 'hidden'
					} w-full lg:flex lg:items-center lg:w-auto mt-4 lg:mt-0`}
				>
					<ul className="lg:flex items-center space-y-4 lg:space-y-0 lg:space-x-8">
						{MENU_ITEMS.map((item) => (
							<MenuItem
								key={item.id}
								to={item.path}
								label={t(item.label)}
								variant={item.id === activeId && VARIANT.ACTIVE}
							/>
						))}
						<Button
							variant={VARIANT.GRADIENT}
							className="bg-primary text-white flex justify-center items-center h-10 w-[142px] gap-[6px]"
						>
							<img className="w-5" src="/img/icon/phone.png" alt=""/>
							<div className="flex flex-col justify-center py-3">
								<p className="text-start text-[10px] font-bold h-fit mb-[-5px]">Hotline</p>
								<p className="text-lg font-bold h-fit">19006038</p>
							</div>
						</Button>
						<NewLink className='md:hidden' to={PATH.DOWNLOAD} onClick={onDownload}>
							<Button
								variant={VARIANT.GRADIENT}
								className="bg-primary mt-4 text-white flex justify-center items-center h-10 w-[142px] px-2"
							>
								<img src="/img/icon/download.png" alt=""/>
								<p className="text-[12px] font-bold w-[84px]">Tải ứng dụng GooPay</p>
							</Button>
						</NewLink>
						
						<Button
							variant={VARIANT.GRADIENT}
							className="hidden bg-primary text-white md:flex justify-center items-center h-10 w-[142px] px-2"
							onClick={() => setIsPopupOpen(true)}
						>
							<img src="/img/icon/download.png" alt=""/>
							<p className="text-[12px] font-bold w-[84px]">Tải ứng dụng GooPay</p>
						</Button>
					</ul>
				</nav>
			</div>
			
			<Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
				<AppDownload />
			</Popup>
		</header>
	);
};

export default Header;
