import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import swipeAnimation from './swipe-left.json';

const SwipeAnimation = () => {
  const lottieRef = useRef();
  
  useEffect(() => {
    // You can control the animation
    if (lottieRef.current) {
      // Play
      lottieRef.current.play();
      
      // Set speed
      lottieRef.current.setSpeed(1.5);
    }
  }, []);
  
  return (
    <Lottie d
      lottieRef={lottieRef}
      animationData={swipeAnimation}
      loop={true}
      autoplay={true}
      onComplete={() => {
        // console.log('Animation completed');
      }}
      onLoopComplete={() => {
        // console.log('Loop completed');
      }}
    />
  );
};

export default SwipeAnimation;
